import range from 'lodash/range';

import {
  endOfMonthDate,
  formatFullMonth,
  startOfMonthDate,
  startOfQuarterDate,
  startOfYearDate,
  subFromDate,
} from '@src/utils/date_helpers';

import { TPeriod } from './types';

const buildMonthPeriods = (today: Date): TPeriod[] => {
  return range(0, 12).map((i) => {
    const month = subFromDate(today, { months: i });
    return {
      label:     formatFullMonth(month),
      startDate: startOfMonthDate(month),
      endDate:   endOfMonthDate(month),
    };
  });
};

const buildPeriods = (today: Date): TPeriod[] => {
  return [
    {
      label:     'Last 30 days',
      startDate: subFromDate(today, { days: 30 }),
      endDate:   today,
    },
    {
      label:     'Last 90 days',
      startDate: subFromDate(today, { days: 90 }),
      endDate:   today,
    },
    {
      label:     'Month to date',
      startDate: startOfMonthDate(today),
      endDate:   today,
    },
    {
      label:     'Quarter to date',
      startDate: startOfQuarterDate(today),
      endDate:   today,
    },
    {
      label:     'Year to date',
      startDate: startOfYearDate(today),
      endDate:   today,
    },
    {
      label: 'All time',
    },
  ];
};

const buildMonthlyPeriodsForMonthlyRefresh = (today: Date): TPeriod[] => {
  return [
    {
      label:     'Current month',
      startDate: startOfMonthDate(today),
      endDate:   endOfMonthDate(today),
    },
    {
      label:     'Last month',
      startDate: startOfMonthDate(subFromDate(today, { months: 1 })),
      endDate:   endOfMonthDate(subFromDate(today, { months: 1 })),
    },
    {
      label:     'Last 3 months',
      startDate: startOfMonthDate(subFromDate(today, { months: 3 })),
      endDate:   endOfMonthDate(subFromDate(today, { months: 1 })),
    },
    {
      label:     'Quarter to month',
      startDate: startOfQuarterDate(today),
      endDate:   endOfMonthDate(today),
    },
    {
      label:     'Year to month',
      startDate: startOfYearDate(today),
      endDate:   endOfMonthDate(today),
    }
  ];
};

export {
  buildMonthPeriods,
  buildPeriods,
  buildMonthlyPeriodsForMonthlyRefresh
};
