import React, { FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';

import toastr from '@lib/toastr';
import { QueryKey } from '@src/constants/query_keys';
import {
  useCreateChartOfAccount,
  useDeleteChartOfAccount,
  useUpdateChartOfAccount,
} from '@src/requests/simple_chart_of_accounts';
import { IChartOfAccount } from '@src/types/chart_of_accounts';

import AddBusiness from '@src/components/chart_of_accounts/add_btn/add_business';
import { AccountType, DetailType, ParentCode } from '@src/components/chart_of_accounts/add_btn/inputs';
import useProgressModal from '@src/components/chart_of_accounts/add_btn/progress_modal';
import { Button } from '@src/components/ui_v2/buttons';
import Form from '@src/components/ui_v2/form';
import Tooltip from '@src/components/ui_v2/tooltip';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import MappedDepartment from './mapped_departments';

import styles from '../add_btn/styles.module.scss';

interface IFormInput {
  accountType: string;
  accountTypeName?: string;
  detailType: string;
  detailTypeName?: string;
  categoryName: string;
  categoryCode?: string;
  isSubAccount: string;
  parentCategory?: string;
  businessIds: number[];
  mappingClassIds?: number[];
}

const schema = yup.object().shape({
  accountType:    yup.string().required('Account Type is required'),
  detailType:     yup.string().required('Detail Type is required'),
  categoryName:   yup.string().trim().required('Category Name is required'),
  categoryCode:   yup.string().nullable(),
  isSubAccount:   yup.string(),
  parentCategory: yup.string().when('isSubAccount', {
    is:        'true',
    then:      yup.string().required('Parent Category is required'),
    otherwise: yup.string().notRequired(),
  }),
  businessIds: yup.array().min(1, 'At least one business must be selected').of(yup.number()),
});

const isSubAccountOptions = [
  { value: 'false', label: 'No' },
  { value: 'true', label: 'Yes' },
];

// todo
const CoaEditForm: FC<{ close: () => void; data: IChartOfAccount}> = ({ close, data }) => {
  const originBusinessIds = data.businesses?.map((i) => i.id) || [data.businessId];
  const [totalCount, setTotalCount] = useState(0);
  const [doneCount, setDoneCount] = useState(0);

  const defaultValues = {
    accountType:     data.accType,
    accountTypeName: data.accTypeName,
    detailType:      data.subTypeCode,
    detailTypeName:  data.subType,
    categoryName:    data.name,
    categoryCode:    data.number,
    isSubAccount:    data.parentId ? 'true' : 'false',
    parentCategory:  String(data.parentId),
    mappingClassIds: data.mappedClassIds,
    businessIds:     data.businesses?.map((i) => i.id),
  };

  const formMethods = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { control,
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted } } = formMethods;

  const queryClient = useQueryClient();
  const { mutateAsync: mutateAsyncUpdate } = useUpdateChartOfAccount();
  const { mutateAsync: mutateAsyncCreate } = useCreateChartOfAccount();
  const { mutateAsync: mutateAsyncDelete } = useDeleteChartOfAccount();
  const progressModal = useProgressModal();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (formData: IFormInput) => {
    progressModal.open();
    const params: any = {
      id:              data.id,
      businessId:      data.businessId,
      accType:         formData.accountType,
      accTypeName:     formData.accountTypeName,
      subTypeCode:     formData.detailType,
      subType:         formData.detailTypeName,
      name:            formData.categoryName,
      number:          formData.categoryCode || '',
      parentId:        formData.isSubAccount === 'true' ? formData.parentCategory : '',
      mappingClassIds: formData.mappingClassIds?.map((i) => Number(i)),
    };

    try {
      setIsSubmitting(true);
      const modifiedBusinessIds = formData.businessIds;
      let idsToDelete = originBusinessIds.filter((id) => !modifiedBusinessIds.includes(id));
      // todo Currently, we only have the ID of the selected chart of accounts (COA).
      //  Therefore, we can only delete or edit this specific COA.
      idsToDelete = idsToDelete.filter((i) => i === data.businessId);
      const idsToCreate = modifiedBusinessIds.filter((id) => !originBusinessIds.includes(id));
      // todo
      let idsToEdit = modifiedBusinessIds.filter((id) => originBusinessIds.includes(id));
      idsToEdit = idsToEdit.filter((i) => i === data.businessId);
      setTotalCount(idsToDelete.length + idsToCreate.length + idsToEdit.length);

      const updatePromises = [
        ...idsToDelete.map((id) => mutateAsyncDelete({ id: params.id as number, businessId: id })
          .then(() => setDoneCount((count) => count + 1))),
        ...idsToCreate.map((id) => mutateAsyncCreate({ ...params, businessId: id })
          .then(() => setDoneCount((count) => count + 1))),
        ...idsToEdit.map((id) => mutateAsyncUpdate({ ...params, businessId: id })
          .then(() => setDoneCount((count) => count + 1))),
      ];
      await Promise.all(updatePromises);
      await queryClient.invalidateQueries(QueryKey.simpleChartOfAccounts);
      toastr.success('Successfully updated', 'Success');
      close();
    } catch (error: any) {
      await queryClient.invalidateQueries(QueryKey.simpleChartOfAccounts);
      const errorMessage = error.message || 'An unknown error occurred';
      toastr.error(`Failed to update: ${errorMessage}`, 'Error');
      close();
    } finally {
      progressModal.props.onDone();
      setTotalCount(0);
      setDoneCount(0);
      setIsSubmitting(false);
    }
  };
  const isSubAccount = watch('isSubAccount');
  const accountType = watch('accountType');
  const detailType = watch('detailType');

  const detailTypeFieldProps = {
    label:       'Detail Type',
    placeholder: 'Select Detail Type',
    error:       errors.detailType?.message,
  };

  const parentCategoryFieldProps = {
    label:       'Parent Category',
    placeholder: 'Select Parent Category',
    error:       errors.parentCategory?.message,
  };

  return (
    <>
      <FormProvider { ...formMethods }>
        <div>
          <Controller
            control={ control }
            name="accountType"
            render={ ({ field: { value, onChange } }) => (
              <AccountType
                error={ errors.accountType?.message }
                value={ value }
                onChange={ (val) => {
                  onChange(val?.code);
                  setValue('accountTypeName', val?.name);
                  setValue('detailType', '', { shouldValidate: isSubmitted });
                } }
              />
            ) }
          />

          {accountType ? (
            <Controller
              key={ accountType }
              control={ control }
              name="detailType"
              render={ ({ field: { value, onChange } }) => (
                <DetailType
                  accountType={ accountType }
                  error={ errors.detailType?.message }
                  value={ value }
                  onChange={ (val) => {
                    onChange(val?.subtypeCode);
                    setValue('detailTypeName', val?.subtypeName);
                    setValue('parentCategory', undefined, { shouldValidate: isSubmitted });
                  } }
                />
              ) }
            />
          ) : (
            <Tooltip.Hover content="Please select account type first">
              <Form.TextField { ...detailTypeFieldProps } disabled />
            </Tooltip.Hover>
          )}

          <Form.TextField
            label="Category Name"
            placeholder="E.g. Food Supplies"
            { ...register('categoryName') }
            error={ errors.categoryName?.message }
          />

          <Form.TextField
            label="Category Code"
            placeholder="Eg 1200"
            { ...register('categoryCode') }
            error={ errors.categoryCode?.message }
          />

          <div className={ styles['radio-row'] }>
            <div>Is this a sub-account of another category?</div>
            <div>
              {
                isSubAccountOptions.map((i) => (
                  <div key={ i.label } className="checkbox checkbox-primary checkbox-circle">
                    <input
                      { ...register('isSubAccount') }
                      id={ `radio_collection_${i.label}` }
                      type="radio"
                      value={ i.value }
                    />
                    <label htmlFor={ `radio_collection_${i.label}` }>
                      { i.label }
                    </label>
                  </div>
                ))
              }
            </div>
          </div>

          {isSubAccount === 'true' && (
            detailType ? (
              <Controller
                key={ detailType }
                control={ control }
                name="parentCategory"
                render={ ({ field: { value, onChange } }) => (
                  <ParentCode
                    businessId={ data.businessId }
                    error={ errors.parentCategory?.message }
                    subType={ detailType }
                    value={ value }
                    onChange={ onChange }
                  />
                ) }
              />
            ) : (
              <Tooltip.Hover content="Please select detail type first">
                <Form.TextField { ...parentCategoryFieldProps } disabled />
              </Tooltip.Hover>
            )
          )}

          <MappedDepartment businessId={ data.businessId } />

          <AddBusiness businesses={ data.businesses } />

          <div className={ styles['submit-panel'] }>
            <Button disabled={ isSubmitting } type="button" variant="primary" onClick={ handleSubmit(onSubmit) }>
              { isSubmitting ? <SpinnerIcon spin /> : 'Save' }
            </Button>
          </div>
        </div>
      </FormProvider>

      <progressModal.Component
        editModal
        doneCount={ doneCount }
        totalCount={ totalCount }
        { ...progressModal.props }
      />
    </>
  );
};

export default CoaEditForm;
